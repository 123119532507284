import { Accordion, AccordionHeader, AccordionItem, AccordionPanel, makeStyles, shorthands, tokens } from '@fluentui/react-components'
import { Fragment } from 'react/jsx-runtime'
import { useAttrs } from '../../attr/state/context'
import { getSameStrict } from '../../util/web/array'
import type { AnnotShape } from '../shape/shape'
import { AnnotGroupIndicator } from './part/indicator'
import { AnnotGroupMenu } from './part/menu'
import { AnnotShapeButton } from './part/shape'
import { AnnotOverviewTitle } from './part/title'
import { AnnotOverviewTotal } from './part/total'

const useStyles = makeStyles({
  group: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
    ...shorthands.borderBottom('solid', '1px', tokens.colorNeutralStroke1),
    ...shorthands.padding(tokens.spacingVerticalXS, 0),
  },
  details: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    ...shorthands.gap(tokens.spacingVerticalXXS),
  },
  overview: {
    display: 'flex',
    justifyContent: 'flex-start',
  },
  plus: {
    color: tokens.colorNeutralForeground4,
  },
  total: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  action: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  panel: {
    marginLeft: 0,
  },
  header: {
    display: 'flex',
  },
  accordion: {
    'minWidth': 'auto',
    '> button': {
      paddingLeft: 0,
    },
    '& svg': {
      width: '12px',
      height: '12px',
    },
  },
})

export function AnnotGroup(props: { shapes: AnnotShape[] }): JSX.Element {
  const { shapes } = props

  const s = useStyles()
  const { attrs } = useAttrs()
  const group = getSameStrict(shapes.map(s => s.meta.group))
  // "equip" is always available in an "attr",
  // but the corresponding "attr" for the "group" may not be available yet,
  // because "shapes" are added before "attr".
  const equip = attrs[group]?.equip ?? null

  return (
    <div className={s.group}>
      <div className={s.overview}>
        <AnnotOverviewTitle shapes={shapes} />
      </div>
      <Accordion collapsible>
        <AccordionItem value={group}>
          <div className={s.total}>
            <div className={s.header}>
              <AnnotOverviewTotal shapes={shapes} />
              <AccordionHeader
                expandIconPosition="end"
                className={s.accordion}
                size="small"
              />
            </div>
            <div className={s.action}>
              <AnnotGroupIndicator shapes={shapes} />
              <AnnotGroupMenu group={group} equip={Number(equip)} attrs={attrs} />
            </div>
          </div>
          <AccordionPanel className={s.panel}>
            <div className={s.details}>
              {shapes.map((shape, index) => (
                <Fragment key={shape.id}>
                  {index > 0 ? <span className={s.plus}>+</span> : null}
                  <AnnotShapeButton shape={shape} />
                </Fragment>
              ))}
            </div>
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
    </div>
  )
}
