import type { BoxModel } from 'tldraw'
import { ATTR_EQUIP_VALUES } from '../../attr/field/equip/value'
import { server } from '../../util/data/server'
import { t } from '../../util/intl/t'
import type { PredictFetchCrop } from '../fetch/crop'
import type { PredictFetch } from '../fetch/type'
import { parsePredictBoxes } from '../parse/box'
import type { PredictModelBase } from './type'

export function isValidCropSamples(samples: PredictFetchCrop[], areas: PredictFetchCrop[]): boolean {
  const maxCropSample = samples.reduce((max, sample) => {
    const { w, h } = sample.source
    return Math.max(max, w, h)
  }, 0)

  const minCropArea = areas.reduce((min, area) => {
    const { w, h } = area.source
    return Math.min(min, w, h)
  }, Number.POSITIVE_INFINITY)

  return maxCropSample <= minCropArea
}

async function crop(props: {
  img: HTMLImageElement
  box: BoxModel
}): Promise<Blob> {
  return new Promise((resolve) => {
    const { img, box } = props

    // Prepare canvas
    const canvas = document.createElement('canvas')
    const ctx = canvas.getContext('2d')
    if (ctx === null)
      throw new Error('Failed to get context')

    // Draw image
    const { x, y, w, h } = box;
    [canvas.width, canvas.height] = [w, h]
    ctx.drawImage(img, x, y, w, h, 0, 0, w, h)

    // Export
    canvas.toBlob((blob) => {
      if (blob === null)
        throw new Error('Failed to get blob')
      resolve(blob)
    }, 'image/png')
  })
}

const fetch: PredictFetch = async (input) => {
  const normalized = await server.fireProtectionSprinkleHeadsNormalize({
    max: input.samples.reduce((max, sample) => {
      const { w, h } = sample.source
      return Math.max(max, w, h)
    }, 0),
    pageID: input.page,
    scale: input.area.source.scale,
  })

  const img = new Image()
  img.src = `data:image/png;base64,${normalized.payload}`

  await new Promise((resolve) => {
    img.onload = resolve
  })

  const samples = input.samples.map((sample) => {
    return crop({ img, box: sample.source })
  })

  const cropArea = {
    scale: input.area.source.scale,
    x: input.area.source.x / input.area.source.scale,
    y: input.area.source.y / input.area.source.scale,
    w: input.area.source.w / input.area.source.scale,
    h: input.area.source.h / input.area.source.scale,
  }

  const raw = await server.predictFireProtectionSprinkleHeadsByAi({
    image: await crop({ img, box: input.area.source }),
    pageID: input.page,
    sessionID: input.session,
    groupID: crypto.randomUUID(), // Unused
    templateImages: await Promise.all(samples),
    cropping: JSON.stringify(cropArea),
  })

  const boxes = parsePredictBoxes({
    // @TODO: Support generic box, like in pipeline?
    boxes: raw.boxes.map(box => ({
      box: box.box,
      label: undefined,
    })),
    transform: input.area.globalise,
    fallbackEquip: ATTR_EQUIP_VALUES.SPRINKLER_HEAD,
    aiPredictionRemaining: raw.aiPredictionRemaining,
  })

  return boxes
}

export const PredictModelFireHead = {
  value: 'fire-head',
  label: t('predict.model.fire-head'),
  system: 'fire',
  sample: true,
  fetch,
  cleanUp: () => { },
  segment: null,
  additionalShape: false,
} as const satisfies PredictModelBase
