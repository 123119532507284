import type { UseQueryResult } from '@tanstack/react-query'
import { useQuery } from '@tanstack/react-query'
// To support Safari/iPad
// https://github.com/mozilla/pdf.js/wiki/Frequently-Asked-Questions#which-browsersenvironments-are-supported
import { GlobalWorkerOptions, getDocument } from 'pdfjs-dist/legacy/build/pdf.mjs'
import pdfjsWorker from 'pdfjs-dist/legacy/build/pdf.worker?url'
import { server } from '../../util/data/server'
import { getStrict } from '../../util/web/primitive'
import { PAGE_ID } from '../state/id'

GlobalWorkerOptions.workerSrc = pdfjsWorker

export type PagePdf = {
  // Maybe it's better to use off-screen canvas?
  // But "transfer to image bitmap" would clear the off-screen canvas every time,
  // while we want to copy the canvas content to other places later.
  canvas: HTMLCanvasElement
  width: number
  height: number
}

/**
 * PDF is rendered on a canvas, which most browsers limit to 16k pixels in either dimension.
 * Most PDFs have the original size of around 1k to 2k pixels, so 8x is a safe scale.
 * See: https://developer.mozilla.org/en-US/docs/Web/HTML/Element/canvas#maximum_canvas_size
 */
export const PAGE_PDF_SCALE = 8

/**
 * It's intentional that this is a different query than the "page detail" query,
 * even though they use the same endpoint.
 * This is because "get page detail" returns a new "signed url" on each request,
 * which causes caching issues easily.
 * For example, if the queries are the same,
 * then updating the page's scale would unexpectedly cause the PDF to reload.
 */
export function usePagePdf(): UseQueryResult<
  PagePdf,
  // Important: The query fn here does not only get the URL,
  // but also fetches and reads the PDF, so error could be more than API error
  Error
  > {
  return useQuery({
    queryKey: ['pdf'],
    queryFn: async (): Promise<PagePdf> => {
      const { signedURL: url } = await server.getPageDetail(PAGE_ID)
      const pdf = await getDocument(url).promise

      // Our PDF files have only 1 page by design
      const page = await pdf.getPage(1)
      const viewport = page.getViewport({ scale: PAGE_PDF_SCALE })
      // const pdfResult = await getPdfCacheResult()
      // await storePdfCache(pdfResult)
      // const pdf = await getDocument(pdfResult.pdf.fileBase64).promise

      // Our PDF files have only 1 page by design
      // const page = await pdf.getPage(1)
      // const viewport = page.getViewport({ scale: PAGE_PDF_SCALE })

      // Render the page on a canvas
      const canvas = document.createElement('canvas')
      canvas.width = viewport.width
      canvas.height = viewport.height
      const context = getStrict(canvas.getContext('2d'))
      await page.render({ canvasContext: context, viewport }).promise

      // Clean up
      page.cleanup()
      await pdf.cleanup()
      await pdf.destroy()

      return {
        canvas,
        width: viewport.width / PAGE_PDF_SCALE,
        height: viewport.height / PAGE_PDF_SCALE,
      }
    },
  },
  )
}
